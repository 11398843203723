<template>
  <v-app class="app">
    <router-view class="bar" />
  </v-app>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
* {
  font-family: 'DM Sans', sans-serif;
}
*::-webkit-scrollbar {
  display: none;
}
.v-menu__content {
  overflow-y: scroll;
}
.v-menu__content::-webkit-scrollbar {
  display: block;
  width: 3px;
  background: white;
}
.v-menu__content::-webkit-scrollbar-thumb {
  background: #004750;
}
</style>
