import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import './../assets/css/globals.css'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#004750',
        secondary: '#49D1AB',
        accent: '#CDEEF4',
        error: '#FF3C2D',
        info: '#2196F3',
        success: '#43B02C',
        warning: '#FFC107'
      }
    }
  }
})
